<template>
  <div class="header-main-container" :class="{ mobile: isMobile }">
    <div class="inner-container">
      <div class="inner-wrapper">
        <div class="login-register-wrapper">
          <!-- <div class="cs-wrapper">
            <span class="cs-1">고객센터(주말, 공휴일 휴무)</span>
            <span class="cs-2">평일 AM 9:00 ~ PM 6:00</span>
            <span class="cs-3">02-2636-8003</span>
          </div> -->
          <div class="account-wrapper" v-if="!isLoggedIn">
            <span class="account-1" @click="clickAccount('id')">아이디찾기</span>
            <span class="account-2" @click="clickAccount('password')">비밀번호찾기</span>
          </div>
          <div class="login-register-container">
            <div v-if="isLoggedIn" class="login-status">
              <p @click="handleLogout" class="status-text">로그아웃</p>
            </div>

            <div v-else class="login-status">
              <router-link to="/login"><p class="status-text">로그인</p></router-link>
            </div>

            <div v-if="isLoggedIn" class="register-status">
              <router-link to="/mypage/myinfo"
                ><p class="status-text-bold">마이페이지</p></router-link
              >
            </div>

            <div v-else class="register-status">
              <router-link to="/register"><p class="status-text-bold">회원가입</p></router-link>
            </div>
          </div>
        </div>

        <span class="divider"></span>

        <div class="menu-container" :class="{ mobile: isMobile }">
          <div class="logo-container">
            <router-link to="/"
              ><img src="@/assets/img/futureon-main-logo.png" class="logo"
            /></router-link>
          </div>
          <div class="mobile-menu-links" :class="{ mobile: isMobile, open: isMenuOpen }">
            <button @click="toggleMenu" class="menu-button" v-if="isMobile && !isMenuOpen">
              <v-icon>mdi-menu</v-icon>
            </button>
            <v-tabs
              v-if="!isMobile"
              v-model="tab"
              bg-color="primary"
              align-tabs="end"
              color=""
              slider-color="#1C1C1E"
              slider-size="0"
            >
              <v-menu
                open-on-hover
                offset-y
                close-on-click
                close-on-content-click
                transition="scale-transition"
                v-model="menu.selected"
                v-for="(menu, index) in menus"
                :key="index"
              >
                <template #activator="{ on, attr }">
                  <v-tab class="headerMenu" v-on="on" v-bind="attr" :to="menu.link">
                    {{ menu.name }}
                    <span v-if="menu.idx === 'academy' || menu.idx === 'review'" class="hot-label"
                      >HOT</span
                    >
                  </v-tab>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in menu.subMenu"
                    :key="index"
                    :value="index"
                    active-class="highlighted"
                    style="cursor: pointer"
                    :to="item.link"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
            <div v-if="isMobile && isMenuOpen" class="mobile-menu">
              <div class="mobile-login-register-wrapper">
                <div class="mobile-login-register-container">
                  <div class="mobile-home-link">
                    <span @click="clickHome" class="mobile-status-text">HOME</span>
                  </div>
                  <div v-if="isLoggedIn" class="mobile-login-status">
                    <span @click="logout" class="mobile-status-text">로그아웃</span>
                  </div>

                  <div v-else class="mobile-login-status">
                    <router-link to="/login"
                      ><span class="mobile-status-text">로그인</span></router-link
                    >
                  </div>

                  <div v-if="isLoggedIn" class="mobile-register-status">
                    <router-link to="/mypage/myinfo"
                      ><span class="mobile-status-text-bold">마이페이지</span></router-link
                    >
                  </div>

                  <div v-else class="mobile-register-status">
                    <router-link to="/register"
                      ><span class="mobile-status-text-bold">회원가입</span></router-link
                    >
                  </div>
                </div>
              </div>
              <div class="mobile-menu-header">
                <span class="mobile-menu-title">MENU</span>
                <v-icon @click="closeMenu">mdi-close</v-icon>
              </div>
              <ul class="mobile-menu-list">
                <li
                  v-for="(menu, index) in menus"
                  :key="index"
                  class="mobile-menu-item"
                  @click="toggleSubMenu(index)"
                >
                  <div class="menu-item-header">
                    <span class="mobile-menu-name" :class="{ highlighted: menu.selected }">{{
                      menu.name
                    }}</span>
                    <span class="mobile-menu-icon">
                      <v-icon v-if="menu.selected">mdi-menu-down</v-icon>
                      <v-icon v-else>mdi-menu-up</v-icon>
                    </span>
                  </div>
                  <ul
                    v-if="menu.subMenu.length"
                    :class="{ 'mobile-submenu-list': true, open: menu.selected }"
                  >
                    <li
                      v-for="(item, subIndex) in menu.subMenu"
                      :key="subIndex"
                      class="mobile-submenu-item"
                    >
                      <v-icon>mdi-circle-small</v-icon>
                      <router-link :to="item.link" @click.native="closeMenu">{{
                        item.title
                      }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" class="menu-backdrop" @click="closeMenu"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HeaderArea",
  data: () => ({
    tab: null,
    menuSelected: false,
    isMenuOpen: false,
    menus: [
      {
        name: "국내/해외선물 Live",
        idx: "live",
        link: "/futures/futureslive",
        selected: false,
        subMenu: [
          { title: "이용안내", link: "/futures/futuresuse" },
          { title: "라이브", link: "/futures/futureslive" },
          { title: "녹화방송", link: "/futures/futuresVod" },
        ],
      },
      {
        name: "전문가 카톡방",
        idx: "expert",
        link: "/expertkakao",
        selected: false,
        subMenu: [{ title: "전문가 카톡방", link: "/expertkakao" }],
      },
      {
        name: "교육 아카데미",
        idx: "academy",
        link: "/academy",
        selected: false,
        subMenu: [{ title: "강의 시리즈", link: "/academy" }],
      },
      {
        name: "유튜브",
        idx: "youtube",
        link: "/youtube",
        selected: false,
        subMenu: [
          { title: "유튜브", link: "/youtube" },
          { title: "기초강의", link: "/basic" },
        ],
      },
      {
        name: "투자정보",
        idx: "invest",
        link: "/invest/calendar",
        selected: false,
        subMenu: [
          { title: "주간캘린더", link: "/invest/calendar" },
          { title: "일일시황", link: "/invest/daily" },
          { title: "기술적 분석", link: "/invest/analysis" },
        ],
      },
      {
        name: "솔직후기",
        idx: "review",
        link: "/review/profit",
        selected: false,
        subMenu: [
          { title: "수익인증", link: "/review/profit" },
          { title: "이용후기", link: "/review/list" },
          // { title: "실미도후기", link: "/review/silmido" },
        ],
      },
      {
        name: "이벤트",
        idx: "event",
        link: "/event/now",
        selected: false,
        subMenu: [
          { title: "진행중인이벤트", link: "/event/now" },
          { title: "지난이벤트", link: "/event/expired" },
          { title: "당첨자발표", link: "/event/winner" },
        ],
      },
      {
        name: "고객센터",
        idx: "cs",
        link: "/notice/list",
        selected: false,
        subMenu: [
          { title: "공지사항", link: "/notice/list" },
          { title: "Q&A", link: "/notice/qna" },
        ],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    handleLogout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        this.logout().then(() => {
          window.location.reload();
        });
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleSubMenu(index) {
      this.menus[index].selected = !this.menus[index].selected;
    },
    clickHome() {
      this.$router.push("/");
      this.closeMenu();
    },
    clickAccount(tab) {
      this.$router.push({
        name: "FindAccount",
        query: { tab: tab },
      });
      this.closeMenu();
    },
  },
};
</script>
<style scoped>
.header-main-container {
  display: flex;
  width: 100%;
  position: relative;
  background: #fff;
  z-index: 2111;
  margin: 0;
  padding: 0;
  border-bottom: 0.125rem solid #4589ff;
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.login-register-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 40px;
  background: #fff;
}

.divider {
  width: 100%;
  height: 0.063rem;
  background: #e0e0e0;
}

.login-register-container {
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  height: 100%;
}

.login-status,
.register-status {
  flex: 1;
  min-width: 5.625rem;
  height: 100%;
  max-height: 4rem;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 0.063rem solid #e0e0e0;
  cursor: pointer;
}

.register-status {
  background: #4589ff;
}

.login-status {
  border-left: 0.063rem solid #e0e0e0;
}

.register-status,
.login-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-status p,
.login-status p {
  margin-bottom: 0 !important;
}

.register-status a,
.login-status a {
  text-decoration: none;
}

.status-text,
.status-text-bold {
  font-size: 0.875rem;
  text-align: center;
  color: #424242;
}

.status-text-bold {
  font-weight: 600;
  color: #424242;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 5rem;
  position: relative;
  background: #fff;
}

.menu-container.mobile {
  padding: 1.25rem 0;
}

.headerMenu {
  display: flex;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap !important;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  color: #212121 !important;
  overflow: hidden;
}
.headerMenu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  background-color: #f8faff;
  border-bottom: 2px solid #4589ff;
  transition: 0.3s;
  z-index: -1;
  transform-origin: top left;
}
.headerMenu:hover {
  color: #4589ff !important;
}
.mobile-menu-links
  .v-tabs
  .v-item-group
  .v-slide-group__wrapper
  .v-slide-group__content
  .headerMenu:hover::after {
  transform: translateX(0);
}
.v-menu__content .v-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.v-menu__content .v-list::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  background-color: #f8faff;
  border-bottom: 2px solid #4589ff;
  transition: 0.3s;
  z-index: -1;
  transform-origin: top left;
}

.v-menu__content .v-list .v-list-item {
  display: flex;
  text-align: center;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  -webkit-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}
.v-menu__content .v-list .v-list-item:hover {
  color: #4589ff !important;
  background: linear-gradient(var(--gradient-angle), #4589ff, #fff) !important;
}
.v-menu__content .v-list .v-list-item__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none !important;
  width: 97%;
  height: 44px;
  margin: auto;
  outline: none;
  border: none;
  font-size: 0.85rem;
  font-weight: bold;
}
.v-menu__content .v-list .v-list-item__title:hover {
  background-color: #f8faff !important;
}
@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}
@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.logo {
  width: auto;
  height: 100%;
}

/*어딘지 css 문제 있음.*/
.mobile-login-register-wrapper {
  display: flex;
  align-items: center;
  height: 5%;
  width: 100%;
  background: #4589ff;
  border-bottom: 0.063rem solid #fff;
}
.mobile-login-register-container {
  display: flex;
  height: 100%;
  width: 100%;
  background: #4589ff;
}
.mobile-home-link,
.mobile-login-status,
.mobile-register-status {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #4589ff;
}
.mobile-login-status,
.mobile-register-status {
  border-left: 0.063rem solid #fff;
}

.mobile-status-text,
.mobile-status-text-bold {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.mobile-menu-links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2115;
}

.mobile-menu-links.mobile {
  display: flex;
}

.mobile-menu-links.mobile.open {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0px;
  width: 80%;
  height: 100%;
  z-index: 10000;
  overflow-y: auto;
  background: #fff;
}

.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  min-width: 1.5rem;
  min-height: 1.5rem;
  cursor: pointer;
}
.menu-button i {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  font-size: 2rem;
  color: #000000;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2115;
}

.mobile-menu-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0;
  z-index: 2115;
}
.mobile-menu-list ul {
  padding: 0;
}

.mobile-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 0.1rem solid #e5e5e5;
  cursor: pointer;
}
.menu-item-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;
}
.mobile-menu-name {
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  margin-left: 10%;
}
.mobile-menu-name.highlighted {
  color: #4589ff;
}
.mobile-menu-icon {
  margin-left: auto;
  margin-right: 10%;
}

.mobile-submenu-list {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.6s ease-in-out;
  background-color: transparent;
  opacity: 0;
}
.mobile-submenu-list.open {
  width: 100%;
  max-height: 35rem;
  opacity: 1;
}
.mobile-submenu-item {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  position: relative;
  width: 100%;
  background: #eceff1;
  padding-left: 10%;
  transition: background-color 1s ease-in-out;
}
.mobile-submenu-item a {
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: 500;
  color: #00000094;
  text-decoration: none;
}

.mobile-menu-header {
  height: 7.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4589ff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 10%;
}
.mobile-menu-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #fff;
}
.mobile-menu-header button {
  font-size: 2rem;
  color: #fff;
}

.menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2114;
}

.cs-wrapper {
  display: flex;
  flex-direction: column;
  padding: 7.75px 5px;
  line-height: 1rem;
  margin-right: 10px;
  border: 1px solid #4589ff;
  border-radius: 5px;
}

.cs-1 {
  font-size: 0.85rem;
  font-weight: bold;
  color: #4589ff;
}
.cs-2 {
  font-size: 0.85rem;
  font-weight: bold;
}
.cs-3 {
  font-size: 1.25rem;
  font-weight: bold;
}

.account-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
  font-size: 0.85rem;
  height: 100%;
  margin-right: 2px;
}
.account-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-right: 1.5px solid #e0e0e0;
}
.account-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 5px 0 5px;
  cursor: pointer;
}

.hot-label {
  position: absolute;
  color: red;
  font-weight: bold;
  font-size: 0.65rem;
  top: 0;
  right: 0;
}

.header-main-container.mobile .login-status {
  min-width: 70px;
}
.header-main-container.mobile .register-status {
  min-width: 70px;
}
.header-main-container.mobile .status-text {
  font-size: 12px;
}
.header-main-container.mobile .status-text-bold {
  font-size: 12px;
}
.header-main-container.mobile .account-1,
.header-main-container.mobile .account-2 {
  font-size: 12px;
}
</style>
