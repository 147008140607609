var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"dialog-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"dialog",style:({ width: _vm.width + 'px', height: _vm.height + 'px' })},[_c('div',{staticClass:"dialog-header"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"dialog-body"},[_vm._v(_vm._s(_vm.content))]),_c('div',{staticClass:"dialog-footer"},[(_vm.cancel)?_c('button',{staticClass:"dialog-cancel",style:({
          'background-color': _vm.cancelBgColor,
          color: _vm.cancelColor,
          'min-width': _vm.cancelWidth + 'px',
          'min-height': _vm.cancelHeight + 'px',
        }),on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e(),(_vm.confirm)?_c('button',{staticClass:"dialog-confirm",style:({
          'background-color': _vm.confirmBgColor,
          color: _vm.confirmColor,
          'min-width': _vm.confirmWidth + 'px',
          'min-height': _vm.confirmHeight + 'px',
        }),on:{"click":_vm.confirmAction}},[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }