<template>
  <div class="how-future-container" :class="{ mobile: isMobile }">
    <div class="how-future-main-header">
      <span class="main-header">퓨처온 이용방법</span>
      <span class="secondary-header">
        초보자라면 필수로 봐야 하는 기초강의 1-7강 무료로 시청하세요!
      </span>
    </div>
    <div class="how-future-contents-container">
      <div class="how-future-route-header">
        <router-link to="/basic">
          <div class="how-future-route-btn">
            <span>더보기</span>
            <v-icon>mdi-plus</v-icon>
          </div></router-link
        >
      </div>
      <div class="how-future-content">
        <div
          v-if="lectures[0]"
          class="how-future-lecture-main"
          @click="showVideoModal(lectures[0].link)"
        >
          <img :src="lectures[0].imageUrl" alt="lecture-main" />
          <span>{{ lectures[0].title }}</span>
        </div>
        <div class="how-future-lecture-list">
          <div class="lecture-list-container">
            <div
              v-for="(lecture, index) in lectures.slice(1)"
              :key="index"
              class="lecture-list-item"
              @click="showVideoModal(lecture.link)"
            >
              <span>{{ lecture.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HowFuture",
  data: () => ({
    defaultLectures: [
      {
        imageUrl: require("@/assets/img/image-4.png"),
        title: "[해외선물 기초강의] 1강 해외선물 용어정리",
      },
      {
        title: "[해외선물 기초강의] 2강 HTS 설치방법",
      },
      {
        title: "[해외선물 기초강의] 3강 차트 보는 방법",
      },
      {
        title: "[해외선물 기초강의] 4강 호가창 매매법",
      },
      {
        title: "[해외선물 기초강의] 5강 증거금 개념 및 종류",
      },
      {
        title: "[해외선물 기초강의] 6강 오버나잇 위험성",
      },
    ],
    lectures: [],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  mounted() {
    this.fetchLecture();
  },
  methods: {
    async fetchLecture() {
      try {
        const { data } = await this.$axios.get(`${process.env.VUE_APP_API_URL}/lecture/all`, {
          params: {
            type: 0,
          },
        });

        if (data && data.items.length > 0) {
          const first = {
            // imageUrl: require("@/assets/img/image-4.png"),
            imageUrl: this.getThumbnailUrl(data.items[0].link),
            title: data.items[0].main_title,
            link: data.items[0].link,
          };

          const other = data.items.slice(1).map((item) => ({
            title: item.main_title,
            link: item.link,
          }));

          while (other.length < 6) {
            other.push(this.defaultLectures[other.length]);
          }

          this.lectures = [first, ...other];
        }
      } catch (error) {
        console.error(error);
      }
    },
    getThumbnailUrl(link) {
      try {
        const urlObj = new URL(link);
        const videoId = urlObj.searchParams.get("v");
        if (videoId) {
          return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        }

        const pathSegments = urlObj.pathname.split("/");
        const lastSegment = pathSegments.pop() || pathSegments.pop();
        return `https://img.youtube.com/vi/${lastSegment}/0.jpg`;
      } catch (error) {
        const img = require("@/assets/img/futureon-logo-white-1.png");
        return img;
      }
    },
    showVideoModal(link) {
      this.$modal.open({
        body: {
          component: () => import("@/components/YouTubePlayer.vue"),
          props: {
            link: link,
          },
        },
      });
    },
  },
};
</script>
<style>
.how-future-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 37.5rem;
  background: #f1f3f8;
  gap: 1.25rem;
}
.how-future-container.mobile {
  padding: 2.5rem 0;
  height: auto;
  max-height: 100%;
}

.how-future-main-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 75rem;
  max-height: 6rem;
}
.main-header {
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
  color: #212121;
}
.secondary-header {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  color: #424242;
}

.how-future-container.mobile .how-future-main-header {
  height: auto;
  max-height: 100%;
  padding: 0 1.25rem;
}
.how-future-container.mobile .secondary-header {
  display: none;
}

.how-future-contents-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 25.625rem;
}
.how-future-container.mobile .how-future-contents-container {
  height: auto;
  max-height: 100%;
  padding: 0 1.25rem;
}

.how-future-route-header {
  display: flex;
  width: 100%;
  height: 3.25rem;
  justify-content: flex-end;
}
.how-future-container.mobile .how-future-route-header {
  position: absolute;
  top: 5%;
  right: 3%;
}

.how-future-route-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.35rem;
  height: 2.625rem;
  gap: 4px;
  border: 1px solid #424242;
  cursor: pointer;
}
.how-future-route-btn span {
  font-size: 1rem;
  font-weight: 500;
  color: #424242;
}
.how-future-route-btn a,
.how-future-route-btn a:visited {
  text-decoration: none;
  color: #ffffff;
}
.how-future-route-btn:hover {
  border-color: #4589ff;
  transition: border-color 0.3s ease;
}

.how-future-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.how-future-container.mobile .how-future-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  height: auto;
  max-height: 100%;
  padding: 0 1.25rem;
}

.how-future-lecture-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 33.125rem;
  height: 100%;
  gap: 1.25rem;
  cursor: pointer;
}
.how-future-container.mobile .how-future-lecture-main {
  justify-content: center;
  align-items: center;
}

.how-future-lecture-main span {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
  color: #212121;
  white-space: nowrap;
}
.how-future-container.mobile .how-future-lecture-main span {
  display: none;
}

.how-future-lecture-main img {
  width: 100%;
  max-width: 33.125rem;
  height: 100%;
  max-height: 18.625rem;
  object-fit: cover;
  object-position: center;
}
.how-future-container.mobile .how-future-lecture-main img {
  width: 100%;
  height: 100%;
}

.lecture-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.how-future-container.mobile .lecture-list-container {
  width: 100%;
  height: auto;
}

.how-future-lecture-list {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 37.5rem;
  height: 22.5rem;
  border-top: 2px solid #263238;
}
.how-future-container.mobile .how-future-lecture-list {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.lecture-list-item {
  display: flex;
  align-items: center;
  height: 3.75rem;
  border-bottom: 1px solid #dbdbdb;
  font-size: 1rem;
  padding: 0 0.625rem;
  color: #333;
  background-color: #fff;
  transition: background-color 0.3s;
  cursor: pointer;
}
.lecture-list-item span,
.lecture-list-item a:visited {
  text-decoration: none;
  color: #333;
}

.how-future-container.mobile .lecture-list-item {
  width: 100%;
  height: 3.25rem;
}

.lecture-list-item:last-child {
  border-bottom: none;
}

.lecture-list-item:hover {
  background-color: #f7f7f7;
}

.how-future-lecture-list::-webkit-scrollbar {
  width: 6px;
}

.how-future-lecture-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.how-future-lecture-list::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}
</style>
