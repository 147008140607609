<template>
  <v-container fluid class="main-container" :class="{ mobile: isMobile }">
    <UserInfo />
    <CarouselView />
    <QuickBanner />
    <EventBanner />
    <FreeBroad v-if="expertData" :expertData="expertData" />
    <BestFeatures v-if="expertData" :expertData="expertData" />
    <PromotionBanner />
    <LineUp v-if="expertData" :expertData="expertData" />
    <AcademyArea />
    <HowFuture />
    <YoutubeArea />
    <ReviewArea />
    <TradingView />
    <CertiArea />
    <InfoArea />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserInfo from "../components/main/UserInfo.vue";
import CarouselView from "../components/main/Carousel";
import QuickBanner from "../components/main/QuickBanner";
import EventBanner from "../components/main/EventBanner.vue";
import FreeBroad from "../components/main/FreeBroad.vue";
import BestFeatures from "../components/main/BestFeatures.vue";
import PromotionBanner from "@/components/main/PromotionBanner.vue";
import LineUp from "../components/main/LineUp.vue";
import AcademyArea from "../components/main/Academy.vue";
import HowFuture from "../components/main/HowFuture.vue";
import YoutubeArea from "../components/main/Youtube.vue";
import ReviewArea from "../components/main/Review.vue";
import CertiArea from "../components/main/Certi.vue";
import TradingView from "../components/main/TradingView.vue";
import InfoArea from "../components/main/Information.vue";

export default {
  name: "MainView",
  components: {
    UserInfo,
    CarouselView,
    QuickBanner,
    EventBanner,
    FreeBroad,
    BestFeatures,
    PromotionBanner,
    LineUp,
    AcademyArea,
    HowFuture,
    YoutubeArea,
    ReviewArea,
    TradingView,
    CertiArea,
    InfoArea,
  },
  data: () => ({
    expertData: null,
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  mounted() {
    this.fetchExpertData();
  },
  methods: {
    async fetchExpertData() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/users/expert_search_media`
        );
        this.expertData = response.data || [];
      } catch (error) {
        console.error("There was an error fetching the expert data:", error);
        this.expertData = [];
      }
    },
  },
};
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  max-width: none !important;
  width: 100%;
}
.main-container.mobile {
  overflow: hidden;
  width: 100% !important;
  box-sizing: border-box;
}

.main-container.mobile::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  background: transparent;
}
</style>
