<template>
  <div class="certi-container" :class="{ mobile: isMobile }">
    <div class="certi-wrapper">
      <div class="certi-financial">
        <img :src="financialImg" />
        <span>금융감독원 정식등록업체</span>
      </div>
      <div class="certi-sgi-seoul">
        <img :src="sgiSeoulImg" />
        <span>보증보험 가입완료</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CertiArea",
  data() {
    return {
      financialImg: require("@/assets/img/financial.svg"),
      sgiSeoulImg: require("@/assets/img/sgi_seoul.svg"),
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
};
</script>
<style scoped>
.certi-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 13.375rem;
  background: #fff;
}
.certi-container.mobile {
  margin: 1.25rem 0rem;
  height: auto;
  max-height: 100%;
}

.certi-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 13.375rem;
  background: #fff;
}
.certi-container.mobile .certi-wrapper {
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 0.625rem;
}

.certi-financial,
.certi-sgi-seoul {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 36.125rem;
  height: 100%;
  max-height: 8.125rem;
  border-radius: 0.625rem;
  background: #fff;
  border: 1px solid #e0e0e0;
  gap: 3.25rem;
  white-space: nowrap;
}
.certi-container.mobile .certi-financial,
.certi-container.mobile .certi-sgi-seoul {
  max-width: 100%;
  height: 100%;
  padding: 1.25rem;
  gap: 0.625rem;
}

.certi-financial img,
.certi-sgi-seoul img {
  width: auto;
  height: auto;
}
.certi-container.mobile .certi-financial img,
.certi-container.mobile .certi-sgi-seoul img {
  max-width: 5.25rem;
}

.certi-financial span,
.certi-sgi-seoul span {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000;
}
.certi-container.mobile .certi-financial span,
.certi-container.mobile .certi-sgi-seoul span {
  font-size: 0.7rem;
  font-weight: 600;
  color: #000;
  white-space: normal;
}
</style>
