<template>
  <div class="notice-view" :class="{ mobile: isMobile }" v-if="notice">
    <div class="notice-view-header-main">
      <p>공지사항</p>
      <div class="notice-view-header-prev">
        <button @click="closeView" class="notice-view-header-prev-btn">
          목록
          <svg
            class="notice-view-header-prev-svg"
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 3C0 2.44687 0.446875 2 1 2H13C13.5531 2 14 2.44687 14 3C14 3.55313 13.5531 4 13 4H1C0.446875 4 0 3.55313 0 3ZM0 8C0 7.44688 0.446875 7 1 7H13C13.5531 7 14 7.44688 14 8C14 8.55312 13.5531 9 13 9H1C0.446875 9 0 8.55312 0 8ZM14 13C14 13.5531 13.5531 14 13 14H1C0.446875 14 0 13.5531 0 13C0 12.4469 0.446875 12 1 12H13C13.5531 12 14 12.4469 14 13Z"
              fill="#424242"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="notice-view-content">
      <div class="notice-view-content-main">
        <div class="notice-view-content-title">{{ notice.title }}</div>
        <div class="notice-view-content-date">{{ formatDate(notice.created) }}</div>
      </div>
      <div class="notice-view-content-description">
        <div class="html-content" v-dompurify-html="safeHtml"></div>
      </div>
    </div>
    <div class="notice-view-btn">
      <button class="notice-view-btn-prev" @click="loadPrevNotice" :disabled="isFirstItem">
        <v-icon>mdi-chevron-left</v-icon>
        이전
      </button>
      <button class="notice-view-btn-next" @click="loadNextNotice" :disabled="isLastItem">
        다음
        <v-icon>mdi-chevron-right</v-icon>
      </button>
    </div>
    <infinite-loading @infinite="infiniteHandler"></infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters } from "vuex";

export default {
  name: "NoticeView",
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      localPage: 1,
      localPageCount: 1,
      localLimit: 10,
      localList: [],
      safeHtml: "",
      currentNoticeId: null,
    };
  },
  mounted() {
    this.fetchNoticeList(this.localPage).then(() => {
      this.setCurrentNotice();
      this.loadSafeHtml();
    });
  },
  watch: {
    "$route.query.noticeId": {
      handler() {
        this.setCurrentNotice();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
    notice() {
      return this.localList.find((item) => item.notice_id === this.currentNoticeId);
    },
    isFirstItem() {
      const index = this.localList.findIndex((item) => item.notice_id === this.currentNoticeId);
      return index === 0 && this.localPage === 1;
    },
    isLastItem() {
      const index = this.localList.findIndex((item) => item.notice_id === this.currentNoticeId);
      const isLastNotice = index === this.localList.length - 1;
      return isLastNotice && this.localPage === this.localPageCount;
    },
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format("YYYY-MM-DD HH:mm");
    },
    closeView() {
      this.$emit("close");
      this.$router.push({ name: "NoticeList" });
    },
    async loadPrevNotice() {
      if (this.isFirstItem) return alert("처음에 위치한 글입니다.");

      let currentIndex = this.localList.findIndex(
        (item) => item.notice_id === this.currentNoticeId
      );
      let prevNotice = this.localList[currentIndex - 1];

      if (!prevNotice && this.localPage > 1) {
        this.localPage--;
        await this.fetchNoticeList(this.localPage);
        prevNotice = this.localList[this.localList.length - 1];
      }

      if (prevNotice) {
        this.currentNoticeId = prevNotice.notice_id;
        this.updateUrl();
        this.loadSafeHtml();
      }
    },
    async loadNextNotice() {
      if (this.isLastItem) return alert("마지막에 위치한 글입니다.");

      let currentIndex = this.localList.findIndex(
        (item) => item.notice_id === this.currentNoticeId
      );
      let nextNotice = this.localList[currentIndex + 1];

      if (!nextNotice && this.localPage < this.localPageCount) {
        this.localPage++;
        await this.fetchNoticeList(this.localPage);
        nextNotice = this.localList[0];
      }

      if (nextNotice) {
        this.currentNoticeId = nextNotice.notice_id;
        this.updateUrl();
        this.loadSafeHtml();
      }
    },
    async fetchNoticeList(page) {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/notice/notice_list`,
          {
            params: { page, pageSize: this.localLimit },
          }
        );
        this.localList = response.data.items;
      } catch (error) {
        console.error("Error fetching new notices:", error);
      }
    },
    async infiniteHandler($state) {
      if (this.localPage < this.localPageCount) {
        await this.fetchNoticeList(this.localPage + 1);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async loadSafeHtml() {
      if (this.notice) {
        this.safeHtml = await this.viewHtml(this.notice.content);
        this.$nextTick(() => {
          const videos = document.querySelectorAll(".html-content iframe");
          const images = document.querySelectorAll(".html-content p > img");
          videos.forEach((video) => {
            video.style.width = "100%";
            video.style.height = "56.25vh";
          });
          images.forEach((img) => {
            img.style.width = "100%";
          });
        });
      }
    },
    async viewHtml(html) {
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/content/editor_view_user`,
          {
            html,
          }
        );
        return response.data.message === "Success"
          ? response.data.html
          : "데이터를 불러오는데 실패했습니다.";
      } catch (error) {
        console.error("Error fetching html:", error);
        return "데이터를 불러오는데 실패했습니다.";
      }
    },
    updateUrl() {
      this.$router.push({
        name: "NoticeView",
        query: {
          noticeId: this.currentNoticeId,
          page: this.localPage,
          pageCount: this.localPageCount,
          limit: this.localLimit,
        },
      });
    },
    setCurrentNotice() {
      this.currentNoticeId = Number(this.$route.query.noticeId);
    },
  },
};
</script>
<style scoped>
.notice-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60rem; /* 900px / 15px(font) */
  height: 100%;
  background: #ffffff;
  margin-bottom: 100px;
  gap: 30px;
}
.notice-view.mobile {
  width: 100vw;
  padding: 0 10px;
}

.notice-view-header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  font-size: 2rem;
  letter-spacing: 0.25px;
  line-height: 44px;
  font-weight: 600;
  color: #212121;
}
.notice-view.mobile .notice-view-header-main {
  font-size: 1.5rem;
}

.notice-view-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  border-top: 1px solid #1a237e;
  border-bottom: 1px solid #e0e0e0;
}

.notice-view-content-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.notice-view-content-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #212121;
  line-height: 44px;
  letter-spacing: 0.25px;
  margin-top: 10px;
}

.notice-view-content-date {
  font-size: 1rem;
  color: #757575;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}

.notice-view-content-description {
  font-size: 1rem;
  color: #00000094;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  margin-bottom: 30px;
}

.html-content {
  width: 100%;
}

.notice-view-header-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  width: 57px;
  height: 48px;
}

.notice-view-header-prev-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  gap: 15px;
  color: #424242;
  margin-right: 5px;
}

.notice-view-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  gap: 20px;
}

.notice-view-btn-prev,
.notice-view-btn-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 48px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid #000000de;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #000000de;
}

.notice-view-btn-prev > i,
.notice-view-btn-next > i {
  color: #000000de;
}
</style>
