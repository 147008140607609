import Vue from "vue";
import DialogComponent from "../components/DialogComponent.vue";

const DialogClass = Vue.extend(DialogComponent);
const dialogInstance = new DialogClass();

dialogInstance.$mount(document.createElement("div"));
document.body.appendChild(dialogInstance.$el);

const dialogService = {
  async open(props) {
    Object.assign(dialogInstance, props);
    dialogInstance.show();
  },
  close() {
    dialogInstance.close();
  },
};

export default dialogService;
