import Vue from "vue";
import ModalComponent from "../components/Modal.vue";
import store from "../store";

const ModalClass = Vue.extend(ModalComponent);
const modalInstance = new ModalClass({
  store,
});

modalInstance.$mount(document.createElement("div"));
document.body.appendChild(modalInstance.$el);

const modalService = {
  async open(props) {
    modalInstance.title = props.title || "";
    modalInstance.body = props.body || { component: "div", props: {} };
    modalInstance.buttons = props.buttons || [];
    modalInstance.open();
  },
  close() {
    modalInstance.close();
  },
};

export default modalService;
