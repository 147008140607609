<template>
  <div class="vip_container" v-if="visible" :class="{ mobile: isMobile }">
    <div class="vip_banner">
      <div class="vip_banner_title">VIP 3일 무료체험 신청</div>
      <button @click="routeVipPage" color="white" class="vip_banner_button">신청하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VipBanner",
  computed: {
    ...mapGetters({
      visible: "vipBanner/vipBannerVisible",
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    routeVipPage() {
      this.$router.push("/vipjoin");
    },
  },
};
</script>
<style scoped>
.vip_container {
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 2002;
  bottom: 0;
  transform: translateZ(0);
}
.vip_container.mobile {
  max-width: 100%;
}

.vip_banner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3057b9;
  padding: 0.75rem 0;
  gap: 1.5rem;
}
.vip_container.mobile .vip_banner {
  padding: 0.5rem 0;
  gap: 0.75rem;
}

.vip_banner_title {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
}
.vip_container.mobile .vip_banner_title {
  font-size: 1.5rem;
}

.vip_banner_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 2.5rem;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  border: none;
  border-radius: 0.5rem;
}
.vip_container.mobile .vip_banner_button {
  width: 6.25rem;
  height: 2.25rem;
  font-size: 1rem;
}

@keyframes blink {
  0%,
  50% {
    background-color: red;
  }
  50.1%,
  100% {
    background-color: rgb(13, 7, 41);
  }
}

.vip_banner_button {
  animation: blink 1.5s linear infinite;
}
</style>
