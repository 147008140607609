var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"free-broad",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"free-broad__wrapper"},[(!_vm.isMobile)?_c('div',{staticClass:"free-broad__header"},[_vm._m(0),_vm._m(1)]):_vm._e(),_c('div',{staticClass:"broadcast-container"},[_c('div',{staticClass:"free-broad-container"},[(_vm.isMobile)?_c('div',{staticClass:"free-broad__header__title"},[_c('span',[_vm._v("오늘의 무료 방송")])]):_vm._e(),_vm._l((_vm.processedExpertData),function(freeBroad,index){return _c('div',{key:index,staticClass:"free-broad-card"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"profile"},[_c('div',{staticClass:"profile-image-container",on:{"click":function($event){return _vm.navigateToExpert(freeBroad.expert_id)}}},[_c('div',{staticClass:"triangle-background"}),_c('div',{staticClass:"profile-image"},[_c('img',{attrs:{"src":freeBroad.profile_img3}})]),(_vm.isBroadcastLive(freeBroad))?_c('div',{staticClass:"live-badge-custom"},[_c('div',{staticClass:"live-custom"},[_c('v-icon',[_vm._v("mdi-circle")]),_c('span',[_vm._v("LIVE")])],1)]):_vm._e()])])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"expert-des-container"},[_c('div',{staticClass:"expert-name"},[_c('span',[_vm._v(_vm._s(freeBroad.expert_nickname))])]),_c('div',{staticClass:"expert-home-btn",on:{"click":function($event){return _vm.navigateToExpert(freeBroad.expert_id)}}},[_c('img',{staticClass:"img",attrs:{"alt":`expert_home_svg`,"src":_vm.homeSvg}})]),_c('div',{staticClass:"expert-kakao-btn",on:{"click":function($event){return _vm.openKakaoTalk(freeBroad.kakao_talk)}}},[_c('img',{staticClass:"img",attrs:{"alt":`expert_kakao_svg`,"src":_vm.kakaoSvg}})])]),_c('div',{staticClass:"free-broad-title"},[_c('span',[_vm._v(_vm._s(freeBroad.title))])]),_c('div',{staticClass:"free-broad-date",class:{
                'free-broad-free-date': freeBroad.type === 1,
                'free-broad-record-date': freeBroad.kind === 1,
              }},[_c('span',[_vm._v(_vm._s(_vm.formatDate(freeBroad.start_date, freeBroad.end_date)))])]),_c('div',{staticClass:"free-broad-btn",class:{
                'free-broad-free-broadcast': freeBroad.type === 1,
                'free-broad-record-broadcast': freeBroad.kind === 1,
              },on:{"click":function($event){return _vm.joinBroadcast(freeBroad, freeBroad.expert_id)}}},[_c('span',{staticClass:"bottom-btn"},[_vm._v(" "+_vm._s(freeBroad.type === 1 ? "무료 방송보기" : "녹화 방송보기")+" ")])])])])}),(_vm.isMobile)?_c('div',{staticClass:"free-broad__header__title4"},[_c('span',[_vm._v("공지사항")])]):_vm._e(),_c('NoticeList',{staticClass:"best-notice-list",attrs:{"limit":_vm.noticeLimit}})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"free-broad__header__title"},[_c('span',[_vm._v("오늘의 무료 방송")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"free-broad__header__title4"},[_c('span',[_vm._v("공지사항")])])
}]

export { render, staticRenderFns }