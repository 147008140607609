const state = {
    vipBannerVisible: false,
  },
  mutations = {
    SET_VIP_BANNER_VISIBLE(state, isVisible) {
      state.vipBannerVisible = isVisible;
    },
  },
  actions = {
    toggleVipBanner({ commit, state }) {
      commit("SET_VIP_BANNER_VISIBLE", !state.vipBannerVisible);
    },
  },
  getters = {
    vipBannerVisible: (state) => state.vipBannerVisible,
  };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
