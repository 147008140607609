<template>
  <div class="user-info-container" v-if="!isMobile">
    <div class="user-info-wrapper">
      <div class="info-con" v-if="!isLoggedIn">
        <v-text-field
          outlined
          placeholder="아이디"
          v-model="active_id"
          class="login-input-field"
          @keyup.enter="active_id && password ? submitLogin() : null"
        ></v-text-field>
        <v-text-field
          outlined
          placeholder="비밀번호"
          v-model="password"
          class="login-input-field"
          type="password"
          @keyup.enter="active_id && password ? submitLogin() : null"
        ></v-text-field>
        <v-btn class="white--text" variant="tonal" color="#4589ff" @click.prevent="submitLogin">
          <span>로그인</span>
        </v-btn>
        <v-btn class="white--text" variant="tonal" color="#4589ff" @click="clickRegister">
          <span>회원가입</span>
        </v-btn>
      </div>
      <div class="info-con2" v-if="isLoggedIn">
        <div class="con2-nick">
          <span>필명&nbsp;:&nbsp;{{ userNickname }}</span>
          <div class="sett" @click="clickMyPage">
            <img class="img" :alt="`setting_svg`" :src="settingSvg" />
          </div>
        </div>
        <div class="con2-paid">
          <span v-if="expertNickname">전문가&nbsp;:&nbsp;{{ expertNickname }}</span>
          <span v-if="userType === 'user' && formattedServicePeriod !== ''"
            >서비스기간&nbsp;:&nbsp;{{ formattedServicePeriod }}</span
          >
          <span v-if="userType === 'user' && formattedServicePeriod === ''"
            >가입된 전문가 상품이 없습니다.</span
          >
        </div>
      </div>
      <div class="company-con">
        <div class="cs">
          <div class="hei">
            <img class="img" :alt="`cs_svg`" :src="csSvg" />
          </div>
          <div class="column">
            <span class="title">02-2636-8003</span>
            <span class="des">평일 AM 9:00 ~ PM 6:00</span>
          </div>
        </div>
        <div class="bank">
          <div class="hei">
            <img class="img" :alt="`bank_svg`" :src="bankSvg" />
          </div>
          <div class="column">
            <span class="title">140-013-618264</span>
            <span class="des">신한은행&nbsp;&nbsp;주식회사 퓨처온</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UserInfo",
  data: () => ({
    active_id: "",
    password: "",
    loginError: null,
    userType: "",
    userNickname: "",
    expertNickname: "",
    startDate: "",
    endDate: "",
    csSvg: require("@/assets/img/cs.svg"),
    bankSvg: require("@/assets/img/bank-1.svg"),
    settingSvg: require("@/assets/img/setting.svg"),
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isMobile: "mobile/isMobile",
    }),
    formattedServicePeriod() {
      if (this.startDate === null && this.endDate === null) {
        return "";
      }
      return `${this.$dayjs(this.startDate).format("YYYY.MM.DD")} ~ ${this.$dayjs(
        this.endDate
      ).format("YYYY.MM.DD")}`;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    ...mapActions(["login"]),
    async submitLogin() {
      this.isLoading = true;
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/users/expert_search_id`,
          { expert_id: this.active_id }
        );

        if (response.status === 200 && response.data.length > 0) {
          await this.login({ expert_id: this.active_id, password: this.password });
          this.$router.push("/");
        }
      } catch (error) {
        if (error.response) {
          if (error.response?.data?.message === "USER_NOT_ALLOWED") {
            alert("로그인 불가: 계정이 비활성화되었습니다.");
          } else if (error.response.status === 404) {
            try {
              await this.login({ user_id: this.active_id, password: this.password });
              this.$router.push("/");
            } catch (innerError) {
              if (innerError.response?.data?.message === "USER_NOT_ALLOWED") {
                console.log("inner", innerError.response?.data?.message);
                alert(
                  "로그인 불가: 계정이 비활성화되었습니다. 재가입을 원하시는 경우 고객센터로 연락바랍니다."
                );
              } else {
                console.error("Error logging in:", innerError);
                alert("로그인 실패: 아이디 또는 비밀번호가 일치하지 않습니다.");
              }
            }
          } else {
            alert("로그인 실패: 아이디 또는 비밀번호가 일치하지 않습니다.");
          }
        }
      } finally {
        this.isLoading = false;
        window.location.reload();
      }
    },
    async getInfo() {
      try {
        const response = await this.$axios.post(`${process.env.VUE_APP_API_URL}/users/getInfo`, {});
        const data = response.data;
        this.userType = data.type;
        if (this.userType === "user") {
          this.userNickname = data.data.user_nickname;
          this.expertNickname = data.data.expert_nickname;
          this.startDate = data.data.start_date;
          this.endDate = data.data.end_date;
        } else if (this.userType === "expert") {
          this.expertNickname = "전문가 회원";
          this.userNickname = data.data.expert_nickname;
        }
      } catch (error) {
        console.error("Error fetching info:", error);
      }
    },
    clickRegister() {
      this.$router.push({
        name: "RegisterView",
      });
      this.closeMenu();
    },
    clickMyPage() {
      this.$router.push({
        name: "MyInfo",
      });
    },
  },
};
</script>
<style scoped>
.user-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 80px;
  background-color: #202734;
}

.user-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 100%;
}

.info-con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
}
.info-con .v-input {
  width: 100%;
  max-width: 230px;
  height: 100%;
  max-height: 48px;
  background-color: #fff;
}
.info-con .v-input.v-text-field--outlined >>> .v-input__control > .v-input__slot {
  min-height: 48px !important;
}
.info-con .v-input.v-text-field--outlined >>> .v-text-field__slot {
}
.info-con
  .v-input.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  >>> .v-input__control
  > .v-input__slot {
  padding: 0 12px !important;
}
.info-con button {
  /* width: 2.5vw !important; */
  height: 10vw !important;
  max-height: 48px !important;
  min-width: 0px !important;
}
.info-con button span {
  font-size: clamp(0.8rem, 0.625vw, 1rem) !important;
  font-family: "Gmarket Sans TTF-Bold", Helvetica !important;
  font-weight: 500 !important;
}

.company-con {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 40px;
}
.hei {
  height: 48px;
}
.cs {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 230px;
  height: 100%;
  gap: 20px;
}
.bank {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 230px;
  height: 100%;
  gap: 20px;
}

.cs .column {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.bank .column {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.column .title {
  color: #4589ff;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  font-size: clamp(1rem, 1vw, 20px) !important;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
}
.column .des {
  color: #fff;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  font-size: clamp(0.7rem, 0.65vw, 15px) !important;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
}

.info-con2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.con2-nick {
  display: flex;
  align-items: center;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  color: #fff;
  font-size: clamp(0.7rem, 0.75vw, 1rem) !important;
  gap: 10px;
  width: 100%;
  max-width: 210px;
  border-right: 2px solid #37473f;
}
.sett {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.con2-paid {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  color: #fff;
  font-size: clamp(0.7rem, 0.66vw, 1rem) !important;
  text-align: left;
  width: 100%;
  max-width: 230px;
}
.con2-paid span {
  text-align: start;
  width: 100%;
  max-width: 230px;
}
</style>
