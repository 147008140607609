import Vue from "vue";
import App from "./App.vue";
import "@mdi/font/css/materialdesignicons.css";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ko";
import AppPagination from "./components/AppPagination.vue";
import modalService from "./plugins/modalService";
import dialogService from "./plugins/dialogService";
import VueDOMPurifyHTML from "vue-dompurify-html";

require("@/assets/css/fonts.css");

Vue.config.productionTip = false;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ko");
dayjs.tz.setDefault("Asia/Seoul");

Vue.component("AppPagination", AppPagination);

Vue.prototype.$modal = modalService;
Vue.prototype.$dialog = dialogService;
Vue.prototype.$dayjs = dayjs;

Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_TAGS: ["iframe"],
  },
});

async function init() {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}

init();
