export default {
  namespaced: true,
  state: {
    dialog: false,
  },
  mutations: {
    SET_DIALOG(state, value) {
      state.dialog = value;
    },
  },
  actions: {
    toggleDialog({ commit, state }) {
      commit("SET_DIALOG", !state.dialog);
    },
  },
  getters: {
    dialogState: (state) => state.dialog,
  },
};
