<template>
  <div class="footer-container" :style="footerStyle">
    <div class="footer-inner">
      <div class="footer-header-container" :class="{ mobile: isMobile }">
        <span class="footer-title terms0" @click="goToTerms('terms0')">{{ termsTitle[0] }}</span>
        <span class="footer-title terms1" @click="goToTerms('terms1')">{{ termsTitle[1] }}</span>
        <span class="footer-title terms2" @click="goToTerms('terms2')">{{ termsTitle[2] }}</span>
      </div>

      <span class="footer-divider"></span>

      <div class="footer-content-container" :class="{ mobile: isMobile }">
        <img src="@/assets/img/futureon-logo-white-1.png" class="footer-logo" />
        <div class="footer-text-container">
          <p class="footer-content-text">
            <span
              >주식회사 퓨처온에서 제공하는 데이터와 정보는 투자의 참고자료일 뿐이며 투자권유 또는
              거래를 목적으로 하지 않습니다.</span
            >
            <br />
            <span
              >따라서 본 사이트의 정보 오류 및 지연에 대해서 주식회사 퓨처온 및 정보제공자에게는
              어떠한 책임도 없습니다.
            </span>
            <br />
            <span>또한 이용자는 본 사이트 정보를 제3자에게 배포하거나 재활용할 수 없습니다.</span>
          </p>
          <p class="footer-company-info">
            <span
              >상호: 주식회사 퓨처온 ㅣ 서울 영등포구 영등포로 109, 2층 바열28호(당산동2가, 영등포
              유통상가) ㅣ 대표 : 양세동 ㅣ 고객상담전화: 02-2636-8003</span
            >
            <br />
            <span
              >사업자등록번호 : 764-81-02154 ㅣ 통신판매업신고번호 : 2024-서울영등포-2303 ㅣ E-Mail:
              futureonin01@gmail.com</span
            >
            <br /><br />
            <span>Copyright ⓒ FUTUREON.LTD All Rights Reserved.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterArea",
  data: () => ({
    termsTitle: ["회원약관", "개인정보처리방침", "전문가윤리강령"],
  }),
  computed: {
    ...mapGetters({
      vipBannerVisible: "vipBanner/vipBannerVisible",
      isMobile: "mobile/isMobile",
    }),
    footerStyle() {
      return this.vipBannerVisible ? { "padding-bottom": "4rem" } : {};
    },
  },
  methods: {
    goToTerms(terms) {
      this.$router.push({ name: "TermsView", params: { activeBtn: terms } });
    },
  },
};
</script>
<style scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 20rem;
  position: relative;
  overflow: hidden;
  background: #363639;
  z-index: 1000;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-header-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  min-height: 3.75rem;
  position: relative;
  gap: 1.5rem;
}

.footer-header-container.mobile {
  flex-direction: column;
  gap: 0;
}

.footer-title {
  position: relative;
  cursor: pointer;
}

.footer-header-container.mobile .footer-title {
  width: 100%;
  text-align: center;
  padding: 1% 0;
  border-bottom: 0.05rem solid #e0e0e0;
}

.footer-title.terms1::after,
.footer-title.terms2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -8%;
  width: 0.05rem;
  height: 100%;
  background: grey;
  transition: width 0.3s ease;
}

.footer-header-container.mobile .footer-title.terms1::after,
.footer-header-container.mobile .footer-title.terms2::after {
  width: 0;
  height: 0;
}

.footer-divider {
  width: 100%;
  height: 0.05rem;
  background: #ffffff;
}

.footer-header-container span {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #ffffff;
  white-space: nowrap;
}

.footer-content-container {
  display: flex;
  width: 100%;
  max-width: 75rem;
  min-height: 16.3rem;
  gap: 3.75rem;
  padding: 1% 0;
}

.footer-content-container.mobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footer-logo {
  width: 10rem;
  height: 1.875rem;
}

.footer-text-container {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #bdbdbd;
}

.footer-content-container.mobile .footer-text-container {
  text-align: center;
}
</style>
