import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
import auth from "./auth";
import dialog from "./dialog";
import mobile from "./mobile";
import vipBanner from "./vipBanner";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    dialog,
    mobile,
    vipBanner,
  },
  plugins: [
    persistedState({
      paths: ["auth"],
    }),
  ],
});
