<template>
  <div class="banner_container">
    <dialog class="banner_dialog">
      <div class="card_overlay" v-if="filteredBanners.length"></div>
      <div class="card" v-for="banner in filteredBanners" :key="banner.index">
        <div class="card_text">
          <img :src="banner.banner_img" alt="banner image" @click="goLink(banner.banner_link)" />
        </div>
        <div class="card_actions">
          <label>
            <input type="checkbox" v-model="doNotShowAgain[banner.index]" />
            오늘 하루 이 창 열지 않음
          </label>
          <button class="close_button" @click="closeDialog(banner.index)">
            <v-icon>mdi-close-box</v-icon>
          </button>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

{
  /* <div class="card_title">{{ banner.title }}</div> */
}

export default {
  name: "PopupBanner",
  data: () => ({
    dialog: {},
    doNotShowAgain: {},
    bannerData: [],
  }),
  computed: {
    filteredBanners() {
      return this.bannerData
        .filter((banner) => this.dialog[banner.index])
        .sort((a, b) => (dayjs(b).isBefore(dayjs(a), "day") ? 1 : -1));
    },
  },
  mounted() {
    this.loadBannerData();
  },
  methods: {
    async loadBannerData() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/banner/banner_popup`
        );
        if (response.data.message === "Success") {
          this.bannerData = response.data.data
            .filter((banner) => banner.status === 1)
            .sort((a, b) => a.sequence - b.sequence)
            .slice(0, 2);
          this.initializeDialogs();
        } else if (response.data.message === "Banner not found") {
          console.log("No banner data found.");
        }
      } catch (error) {
        console.error("Banner data could not be loaded:", error);
      }
    },
    initializeDialogs() {
      this.bannerData.forEach((banner) => {
        const today = dayjs().format("YYYY-MM-DD");
        const doNotShow = localStorage.getItem("doNotShowToday" + banner.index);
        const shouldShowDialog = !doNotShow || doNotShow !== today;
        this.$set(this.dialog, banner.index, shouldShowDialog);
      });
    },
    closeDialog(index) {
      if (this.doNotShowAgain[index]) {
        const today = dayjs().format("YYYY-MM-DD");
        localStorage.setItem("doNotShowToday" + index, today);
      }
      this.$set(this.dialog, index, false);
    },
    goLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.banner_container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}
.banner_dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: transparent;
  z-index: 9000;
  border: none;
  gap: 5px;
}
.card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  pointer-events: auto;
  user-select: none;
}
.card_title {
  font-size: 16px;
  font-weight: bold;
}
.card_text img {
  width: 100%;
  height: auto;
}
.card_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.card_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_button {
  cursor: pointer;
}

.drag_area {
  display: flex;
  flex-direction: column;
}

.card {
  cursor: grab;
}

.card:active {
  cursor: grabbing;
}
</style>
