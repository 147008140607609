<template>
  <div v-if="visible" class="dialog-overlay" @click.self="close">
    <div class="dialog" :style="{ width: width + 'px', height: height + 'px' }">
      <div class="dialog-header">{{ title }}</div>
      <div class="dialog-body">{{ content }}</div>
      <div class="dialog-footer">
        <button
          class="dialog-cancel"
          v-if="cancel"
          @click="close"
          :style="{
            'background-color': cancelBgColor,
            color: cancelColor,
            'min-width': cancelWidth + 'px',
            'min-height': cancelHeight + 'px',
          }"
        >
          {{ cancelText }}
        </button>
        <button
          class="dialog-confirm"
          v-if="confirm"
          @click="confirmAction"
          :style="{
            'background-color': confirmBgColor,
            color: confirmColor,
            'min-width': confirmWidth + 'px',
            'min-height': confirmHeight + 'px',
          }"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogComponent",
  props: {
    width: { type: Number, default: 400 },
    height: { type: Number, default: 300 },
    title: { type: String, default: "" },
    content: { type: String, default: "" },
    cancel: { type: Boolean, default: true },
    confirm: { type: Boolean, default: true },
    cancelText: { type: String, default: "Cancel" },
    confirmText: { type: String, default: "Confirm" },
    cancelColor: { type: String, default: "#9e9e9e" },
    confirmColor: { type: String, default: "#4589ff" },
    cancelBgColor: { type: String, default: "#eeeeee" },
    confirmBgColor: { type: String, default: "#4589ff" },
    cancelWidth: { type: Number, default: 50 },
    cancelHeight: { type: Number, default: 30 },
    confirmWidth: { type: Number, default: 50 },
    confirmHeight: { type: Number, default: 30 },
    onConfirm: { type: Function, default: () => {} },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.$emit("cancel");
    },
    confirmAction() {
      if (this.onConfirm) {
        this.onConfirm();
      }
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.dialog-header {
  margin-bottom: 20px;
  font-size: 1.75rem;
  font-weight: 600;
}
.dialog-body {
  margin-bottom: 20px;
  color: #00000099;
  font-size: 1rem;
}
.dialog-footer {
  text-align: right;
}
.dialog-footer {
  display: flex;
  gap: 0.5rem;
}

.dialog-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.75rem;
  min-width: 30px;
  min-height: 20px;
}

.dialog-confirm {
  min-width: 30px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.75rem;
}
</style>
