const state = {
  isMobile: false,
};

const mutations = {
  UPDATE_IS_MOBILE(state, isMobile) {
    state.isMobile = isMobile;
  },
};

const actions = {
  updateMobileContext({ commit }) {
    commit("UPDATE_IS_MOBILE", window.innerWidth < 900);
  },
};

const getters = {
  isMobile: (state) => state.isMobile,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
