<template>
  <!-- <v-app v-if="isDataLoaded" class="app"> -->
  <v-app class="app">
    <PopupBanner />
    <HeaderArea />
    <router-view />
    <FooterArea />
    <QuickMenu v-show="!showQuickMenu" />
    <VipBanner />
  </v-app>
  <!-- <div v-else>Loading...</div> -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import HeaderArea from "@/components/layout/Header.vue";
import FooterArea from "@/components/layout/Footer.vue";
import QuickMenu from "./components/QuickMenu.vue";
import PopupBanner from "./components/layout/PopupBanner.vue";
import VipBanner from "./components/layout/VipBanner.vue";

export default {
  components: {
    HeaderArea,
    FooterArea,
    QuickMenu,
    PopupBanner,
    VipBanner,
  },
  data: () => ({
    isDataLoaded: false,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isMobile: "mobile/isMobile",
      route: "router/route",
      path: "router/path",
    }),
    showQuickMenu() {
      return this.isMobile;
    },
  },
  created() {
    this.validateToken().catch((err) => {
      console.error("token validation error", err);
    });
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      validateToken: "validateToken",
      logout: "logout",
      updateMobileContext: "mobile/updateMobileContext",
    }),
    handleResize() {
      this.updateMobileContext();
    },
    performLogout() {
      this.logout().then(() => {
        console.log("logout success");
        this.$router.push("/");
      });
    },
  },
  watch: {},
};
</script>
<style>
.v-application {
  max-width: 100vw !important;
}
.v-application--wrap {
  max-width: 100vw !important;
}
</style>
