<template>
  <div class="youtube-main-container" :class="{ mobile: isMobile }">
    <div class="youtube-wrapper">
      <svg
        class="background-svg"
        width="467"
        height="226"
        viewBox="0 0 467 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <g opacity="0.06" clip-path="url(#clip0_2502_1320)">
          <path
            d="M403.395 5.91449C385.856 3.37971 300.909 0 233.289 0C165.669 0 81.1439 3.37971 63.3937 5.91449C32.7534 10.984 16.0597 24.7141 7.18462 60.201C4.22624 72.03 0 137.723 0 162.648V168.14C0 193.066 4.01493 258.759 7.18462 270.588C16.0597 306.075 32.7534 319.805 63.3937 324.874C80.9326 327.409 166.091 330.789 233.5 330.789H233.289C300.909 330.789 385.856 327.409 403.395 324.874C434.035 320.016 450.729 306.286 459.815 270.588C462.774 258.548 466.789 193.066 466.789 168.14V162.648C466.789 137.723 462.774 72.03 459.815 60.201C450.94 24.5029 434.247 10.7728 403.395 5.91449Z"
            fill="#D2262B"
          ></path>
          <path d="M177.502 105.616L289.498 164.127L177.502 225.384V105.616Z" fill="white"></path>
        </g>
        <defs>
          <clipPath id="clip0_2502_1320">
            <rect width="467" height="331" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
      <div class="youtube-header-wrapper">
        <span class="youtube-heading">유튜브</span>
        <div class="more-button-container">
          <router-link to="/youtube">
            <span>더보기</span>
          </router-link>
          <v-icon>mdi-plus</v-icon>
        </div>
      </div>
      <div class="content-row">
        <div class="column-content" v-for="youtube in youtubeList" :key="youtube.lecture_id">
          <div class="image-overlay" @click="showVideoModal(youtube.link)">
            <!-- <img src="@/assets/img/image-1.png" class="thumbnail-image" /> -->
            <img :src="getThumbnailUrl(youtube.link)" />
            <div class="overlay-black"></div>
            <svg
              width="50"
              height="39"
              viewBox="0 0 50 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              @click="showVideoModal(youtube.link)"
            >
              <path
                d="M45.9783 4.36414C40.4348 -1.45097 9.55435 -1.45097 4.01087 4.36414C-1.33696 8.58425 -1.33696 30.4048 4.01087 34.6249C9.55435 40.451 40.4348 40.451 45.9783 34.6249C51.3261 30.4048 51.3261 8.59532 45.9783 4.36414ZM34.3152 20.868L21.3261 28.4442C21.087 28.5882 20.8261 28.6547 20.5543 28.6547C20.2826 28.6547 20.0217 28.5882 19.7826 28.4442C19.3043 28.1673 19.0109 27.6467 19.0109 27.0818V11.9404C19.0109 11.3755 19.3043 10.8549 19.7826 10.578C20.2609 10.3011 20.8478 10.29 21.3261 10.578L34.3152 18.1543C34.7935 18.4312 35.0978 18.9628 35.0978 19.5277C35.0978 20.0926 34.8043 20.6132 34.3152 20.9012V20.868Z"
                fill="white"
              ></path>
            </svg>
          </div>
          <div class="text-column">
            <span class="video-title">{{ youtube.main_title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "YoutubeArea",
  data: () => ({
    page: 1,
    pageCount: 0,
    limit: 4,
    youtubeList: [],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  mounted() {
    this.fetchLectures();
  },
  methods: {
    async fetchLectures() {
      try {
        const youtubeData = await this.$axios.get(`${process.env.VUE_APP_API_URL}/lecture/all`, {
          params: {
            type: 1,
            page: this.page,
            pageSize: this.limit,
          },
        });

        this.youtubeList = await youtubeData.data.items
          .map((youtube) => ({
            lecture_id: youtube.lecture_id,
            main_title: youtube.main_title,
            link: youtube.link,
          }))
          .slice(0, 4);
      } catch (error) {
        console.error(error);
      }
    },
    getThumbnailUrl(link) {
      try {
        const urlObj = new URL(link);
        const videoId = urlObj.searchParams.get("v");
        if (videoId) {
          return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        }

        const pathSegments = urlObj.pathname.split("/");
        const lastSegment = pathSegments.pop() || pathSegments.pop();
        return `https://img.youtube.com/vi/${lastSegment}/0.jpg`;
      } catch (error) {
        return require("@/assets/img/futureon-logo-white-1.png");
      }
    },
    showVideoModal(link) {
      this.$modal.open({
        body: {
          component: () => import("@/components/YouTubePlayer.vue"),
          props: {
            link: link,
          },
        },
      });
    },
  },
};
</script>
<style>
.youtube-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 23.75rem;
}
.youtube-main-container.mobile {
  padding: 2.5rem 0;
  height: auto;
  max-height: 100%;
}

.youtube-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  gap: 1.25rem;
}
.youtube-main-container.mobile .youtube-wrapper {
  height: auto;
  max-height: 100%;
  padding: 0 1.25rem;
}

.youtube-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 4rem;
}

.background-svg {
  position: absolute;
  bottom: -20%;
  right: -27.5%;
}
.youtube-main-container.mobile .background-svg {
  display: none;
}

.youtube-heading {
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
  color: #212121;
}

.content-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 12.75rem;
  gap: 1.875rem;
}
.youtube-main-container.mobile .content-row {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto;
  max-height: 100%;
}

.column-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 17.375rem;
  gap: 1.25rem;
}
.youtube-main-container.mobile .column-content {
  width: 100%;
  max-width: 13.25rem;
  height: auto;
  max-height: 100%;
}

.image-overlay {
  display: flex;
  width: 100%;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background: #fff;
  cursor: pointer;
}

.image-overlay img {
  width: 100%;
  max-width: 17.375rem;
  height: 100%;
  max-height: 10rem;
  object-fit: cover;
  object-position: center;
}

.overlay-black {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}
.image-overlay svg {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
.youtube-main-container.mobile .text-column {
  width: 100%;
  max-width: 13.25rem;
}

.video-title {
  display: -webkit-box;
  width: 17.5rem;
  height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.youtube-main-container.mobile .video-title {
  width: 13.25rem;
}

.more-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.35rem;
  height: 2.625rem;
  gap: 4px;
  border: 1px solid #424242;
  cursor: pointer;
}
.more-button-container i {
  font-size: 1rem;
  color: #424242;
}

.more-button-container:hover {
  border-color: #4589ff;
  transition: border-color 0.3s ease;
}

.more-button-container span {
  font-size: 1rem;
  color: #424242;
}
</style>
