<template>
  <div class="academy-container" :class="{ mobile: isMobile }" v-if="lectureList.length > 0">
    <div class="lecture-wrapper">
      <div class="lecture-header">
        <span class="academy-title">퓨처온 교육 아카데미</span>
        <router-link to="/academy">
          <div class="route-btn">
            <span class="academy-route">더보기</span>
            <v-icon>mdi-plus</v-icon>
          </div></router-link
        >
      </div>
      <div class="lecture-main">
        <div class="lecture" v-for="(lecture, index) in lectureList" :key="index">
          <img :src="lecture.imageUrl" @error="handleImageError(index)" alt="lecture image" />
          <div class="lecture-content">
            <span class="lecture-title">{{ lecture.series_title }}</span>
            <span class="lecture-description">{{ lecture.main_title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AcademyArea",
  data: () => ({
    page: 1,
    pageCount: 0,
    limit: 3,
    lectureList: [],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  mounted() {
    this.fetchLectures();
  },
  methods: {
    async fetchLectures() {
      try {
        const {
          data: { message, items },
        } = await this.$axios.get(`${process.env.VUE_APP_API_URL}/lecture/group_order_main`);

        if (message === "Success") {
          this.lectureList = items.map((lecture) => ({
            series_title: lecture.series_title,
            main_title: lecture.main_title,
            imageUrl: this.getThumbnailUrl(lecture.link),
            link: lecture.link,
          }));
        }
      } catch (error) {
        console.log("fetchLectures error");
      }
    },
    getThumbnailUrl(link) {
      const urlObj = new URL(link);
      const videoId = urlObj.searchParams.get("v");
      if (videoId) {
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
      }

      const pathSegments = urlObj.pathname.split("/");
      const lastSegment = pathSegments.pop() || pathSegments.pop();
      return `https://img.youtube.com/vi/${lastSegment}/0.jpg`;
    },
    handleImageError(index) {
      this.$set(this.lectureList, index, {
        ...this.lectureList[index],
        imageUrl: this.defaultLectureList[index].imageUrl,
      });
    },
  },
};
</script>
<style scoped>
.academy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 28.125rem;
  background: #202734;
}
.academy-container.mobile {
  padding: 2.5rem 0;
  height: auto;
  max-height: 100%;
}

.lecture-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 22.125rem;
  background: #202734;
  gap: 1.25rem;
}
.academy-container.mobile .lecture-wrapper {
  height: auto;
  max-height: 100%;
}

.lecture-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 4rem;
}
.academy-container.mobile .lecture-header {
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
}

.lecture-main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.875rem;
  width: 100%;
  height: 100%;
  max-height: 18.75rem;
}
.academy-container.mobile .lecture-main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  height: auto;
  max-height: 100%;
}

.academy-title {
  display: flex;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
  color: #fff;
}

.route-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.35rem;
  height: 2.625rem;
  gap: 4px;
  border: 1px solid #fff;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
}
.route-btn a,
.route-btn a:visited {
  text-decoration: none;
  color: #fff;
}
.route-btn i {
  font-size: 1.25rem;
  color: #fff;
}
.route-btn:hover {
  border-color: #4589ff;
  transition: border-color 0.3s ease;
}

.academy-route {
  display: flex;
  font-size: 1rem;
  color: #fff;
  align-items: center;
  white-space: nowrap;
}

.lecture {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  max-width: 23.75rem;
  height: 100%;
  max-height: 18.75rem;
}
.lecture img {
  width: 100%;
  height: 13.75rem;
  object-fit: cover;
}

.lecture-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.lecture-title {
  display: -webkit-box;
  width: 100%;
  height: 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  color: #fff;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.lecture-description {
  display: -webkit-box;
  width: 100%;
  height: 2rem;
  font-size: 1rem;
  text-align: left;
  color: #82b1ff;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
