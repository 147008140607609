import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { en } from "vuetify/lib/locale";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0544e8",
        primaryDark: "#020541",
        btnBlack: "#35363B",
        darkGrey: "#2c2d3c",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#EF4242",
        info: "#2196F3",
        success: "#1CC966",
        warning: "#FB8C00",
        inputBG: "#fcfcfc",
        selectedBlue: "#1251fc",
        white: "#fff",
      },
    },
  },
});
