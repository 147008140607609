<template>
  <div class="promotion-main" v-if="slides.length > 0" :class="{ mobile: isMobile }">
    <div class="promotion-header">
      <span>퓨처온 프로모션</span>
    </div>
    <section class="swiper-promotion-container">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="promotion-container" ref="swiperPromotionContainer">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
            <div class="promotion-banner">
              <img
                :src="slide.imageUrl"
                @error="handleImageError(index)"
                alt="Banner Image"
                class="banner-image"
                @click="openBannerLink(slide.link)"
              />
              <div class="text-box" @click="openBannerLink(slide.link)">
                <div class="text-detail">자세히 보기</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
  name: "PromotionBanner",
  data: () => ({
    swiper: null,
    swiperPromotionOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      centeredSlides: false,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        1: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    slides: [],
  }),
  mounted() {
    this.fetchPromotionBanner();
  },
  watch: {
    slides() {
      this.initializeSwiper();
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    async fetchPromotionBanner() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/banner/banner_list`,
          {
            params: {
              status: 1,
              area: 1,
            },
          }
        );

        const slides = response.data.map((slide) => ({
          imageUrl: this.isValidImageUrl(slide.banner_img) ? slide.banner_img : "",
          link: slide.banner_link,
        }));

        if (slides.length > 0 && slides.length <= 5) {
          this.slides = slides;
        } else if (slides.length === 0) {
          this.slides = [];
        } else {
          this.slides = slides;
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.slides = [];
        } else {
          console.error("There was an error fetching the promotion data:", error);
        }
      }
    },
    isValidImageUrl(url) {
      return typeof url === "string" && url.trim() !== "";
    },
    handleImageError(index) {
      this.$set(this.slides, index, {
        ...this.slides[index],
        imageUrl: "",
      });
    },
    openBannerLink(link) {
      window.open(link, "_blank");
    },
    initializeSwiper() {
      this.$nextTick(() => {
        if (this.swiper) {
          this.swiper.destroy(true, true);
        }
        this.swiperPromotionOptions.loop = this.slides.length > 4;
        this.swiper = new Swiper(this.$refs.swiperPromotionContainer, this.swiperPromotionOptions);
      });
    },
  },
};
</script>
<style scoped>
.promotion-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  max-height: 465px;
  background-color: #202734;
  gap: 30px;
}

.promotion-header {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 35px;
}
.promotion-header span {
  font-size: 28px;
  line-height: 33.6px;
  font-weight: 700;
  color: #fff;
}

.swiper-promotion-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 260px;
}

.promotion-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.swiper-promotion-container .swiper-button-prev,
.swiper-promotion-container .swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  top: -17%;
  color: #fff;
  border: 1px solid #fff;
  max-height: 36px;
  max-width: 36px;
  width: 100%;
}
.swiper-promotion-container .swiper-button-prev {
  left: auto;
  right: 36px;
}
.swiper-promotion-container .swiper-button-prev::after {
  font-size: 100%;
}
.swiper-promotion-container .swiper-button-next {
  right: 0;
}
.swiper-promotion-container .swiper-button-next::after {
  font-size: 100%;
}

.swiper-promotion-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.promotion-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.text-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #000000b2;
  cursor: pointer;
}
.text-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  height: 100%;
  width: 100%;
}

.swiper-promotion-container .swiper-pagination {
  bottom: -15% !important;
  text-align: center;
}

.swiper-promotion-container .swiper-pagination >>> .swiper-pagination-bullet {
  display: inline-flex;
  padding: 0;
  margin: 0 0.1rem !important;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.swiper-promotion-container .swiper-pagination >>> .swiper-pagination-bullet:hover {
  background-color: #4589ff;
  opacity: 1;
}
.swiper-promotion-container .swiper-pagination >>> .swiper-pagination-bullet-active {
  background-color: #4589ff;
  opacity: 1;
  width: 1.2rem;
  border-radius: 100px;
}

@media (max-width: 1024px) {
  .promotion-header {
    max-width: 900px;
  }
  .swiper-promotion-container {
    max-width: 900px;
  }
  .promotion-container {
    max-width: 900px;
  }
}
@media (max-width: 900px) {
  .promotion-main {
    gap: 15px;
    max-height: 600px;
  }
  .promotion-header {
    max-width: 800px;
  }
  .swiper-promotion-container {
    max-width: 800px;
  }
  .promotion-container {
    max-width: 800px;
  }
  .swiper-promotion-container .swiper-button-prev,
  .swiper-promotion-container .swiper-button-next {
    top: -11%;
  }
}
@media (max-width: 768px) {
  .promotion-header {
    max-width: 620px;
  }
  .swiper-promotion-container {
    max-width: 620px;
  }
  .promotion-container {
    max-width: 620px;
  }
}
@media (max-width: 640px) {
  .promotion-main {
    padding: 20px 0 60px 0;
    gap: 10px;
    max-height: 840px;
  }
  .promotion-header {
    display: flex;
    justify-content: center;
    max-width: 500px;
  }
  .promotion-header span {
    font-size: 24px;
  }
  .swiper-promotion-container {
    max-width: 500px;
    height: 100%;
    max-height: 450px;
  }
  .promotion-container {
    max-width: 500px;
    max-height: 450px;
  }
  .swiper-promotion-container .swiper-pagination {
    bottom: -8% !important;
  }
  .swiper-promotion-container .swiper-button-prev,
  .swiper-promotion-container .swiper-button-next {
    display: none;
  }
}
@media (max-width: 540px) {
  .promotion-main {
    padding: 20px 0 60px 0;
    gap: 10px;
    max-height: 700px;
  }
  .promotion-header {
    display: flex;
    justify-content: center;
    max-width: 420px;
  }
  .promotion-header span {
    font-size: 24px;
  }
  .swiper-promotion-container {
    max-width: 420px;
    height: 100%;
    max-height: 380px;
  }
  .promotion-container {
    max-width: 420px;
    max-height: 380px;
  }
  .text-box {
    height: 50px;
  }
  .text-detail {
    font-size: 20px;
  }
  .swiper-promotion-container .swiper-pagination {
    bottom: -12% !important;
  }
}
@media (max-width: 460px) {
  .promotion-main {
    padding: 0 0 20px 0;
    max-height: 640px;
  }
  .promotion-header {
    display: flex;
    justify-content: center;
    max-width: 340px;
  }
  .promotion-header span {
    font-size: 24px;
  }
  .swiper-promotion-container {
    max-width: 340px;
    height: 100%;
    max-height: 340px;
  }
  .promotion-container {
    max-width: 340px;
    max-height: 340px;
  }
  .swiper-promotion-container .swiper-pagination {
    bottom: -12% !important;
  }
}
</style>
