<template>
  <nav aria-label="Page navigation">
    <ul class="pagination-container">
      <li class="page-item" :class="{ 'disabled': page === 1 }">
        <a href="#" class="page-link" @click.prevent="input(page - 1)">
          <span aria-hidden="true">&lt;</span>
        </a>
      </li>

      <li v-for="item in computedPagination" :key="item.page" class="page-item" :class="{ 'active': item.isCurrentPage }">
        <a href="#" class="page-link" @click.prevent="input(item.page)">
          {{ item.label }}
        </a>
      </li>

      <li class="page-item" :class="{ 'disabled': page === count }">
        <a href="#" class="page-link" @click.prevent="input(page + 1)">
          <span aria-hidden="true">&gt;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
    props : {
        count: { type: Number, default: 0 },
        value:  { type: Number, default: 1 },
    },
    data : () => {
        return {
            page: 0,
        }
    },
    created() {
        this.page = this.value;
    },
    computed: {
      computedPagination() {
        const { count, page } = this;
        const maxPagesToShow = 6;
        const halfPageRange = Math.floor(maxPagesToShow / 2);

        const start = Math.max(
          Math.min(page - halfPageRange, count - maxPagesToShow + 1),
          1
        );
        const end = Math.min(start + maxPagesToShow - 1, count);

        return Array.from({ length: end - start + 1 }, (_, index) => ({
          page: start + index,
          label: (start + index).toString(),
          isCurrentPage: start + index === page,
        }));
      },
    },
    methods : {
        input(page){
            if(page > 0 && page <= this.count){
                this.page = page;
                this.$emit("input", page);
                this.$emit("change", page);
            }
        },
    },
    watch : {
        value(newValue){
            this.page = newValue;
        }
    }
}
</script>

<style scoped>
.pagination-container {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 900px;
  height: 94px;
  gap: 10px;
  margin-top: auto;
  padding-left: 0;
}

.page-item {
  margin: 0;
}

.page-item a {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 13px;
}

.page-item.active a {
  color: white;
  background-color: #007bff;
  border-radius: 4px;
}

.page-item.disabled a {
  color: #ccc;
  pointer-events: none;
  cursor: not-allowed;
}

.page-item a:focus {
  outline: none;
}

.page-item a:hover {
  background-color: #e9ecef;
  border-radius: 4px;
}

.page-link > span {
  font-weight: 400;
  display: inline-block;
  transform: scale(1.2);
}
</style>